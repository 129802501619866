<template>
  <div class="InfoFilter">
    <div v-gap="[$mpadding, $mpadding, $mpadding, $mpadding]">
      <Title :label="$locale['order_by']" />
    </div>
    <LargeButton
      icon="chevron-up"
      :label="$locale['date_asc']"
      :desc="$locale['date_asc_desc']"
      :rightIcon="`${$route.query.sort === 'date_asc' ? 'check' : false}`"
      @click="modal.close(() => setQuery({ sort: 'date_asc' }))"
    />
    <LargeButton
      icon="chevron-down"
      :label="$locale['date_desc']"
      :desc="$locale['date_desc_desc']"
      :rightIcon="`${$route.query.sort === 'date_desc' ? 'check' : false}`"
      @click="modal.close(() => setQuery({ sort: 'date_desc' }))"
    />
  </div>
</template>

<script>
export default {
  props: ["modal"],
};
</script>
